import i18next from 'i18next';

export enum OperationNames {
  FirstSignin = 'WMA.FirstSignin',
  FirstLogin = 'WMA.FirstLogin',
  LoginOpen = 'WMA.LoginOpen',
  LoginEnter = 'WMA.LoginEnter',
  LoginForgetPin = 'WMA.LoginForgetPin',
  ForgetOpen = 'WMA.ForgetOpen',
  ForgetForgetLogin = 'WMA.ForgetForgetLogin',
  ForgetSms = 'WMA.ForgetSms',
  MainOpen = 'WMA.MainOpen',
  MainPurchase = 'WMA.MainPurchase',
  MainRefund = 'WMA.MainRefund',
  MainMenu = 'WMA.MainMenu',
}

export const metricsEvent = (name: OperationNames, data?: Record<string, any>) => {
  if (typeof window !== 'undefined') {
    if (typeof window.maestra === 'function' && i18next.language === 'ro') {
      return window.maestra('async', {
        operation: name,
        data: { ...data },
      });
    }

    if (typeof window.mindbox === 'function' && i18next.language === 'ru') {
      return window.mindbox('async', {
        operation: name,
        data: { ...data },
      });
    }
  } else {
    return null;
  }
};
