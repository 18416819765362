import Box from '@mui/material/Box';
import {
  HelpIcon,
  HomeSidebarIcon,
  LogoutIcon,
  PlayIcon,
  PurchaseSidebarIcon,
  RefundSidebarIcon,
} from 'shared/ui/icon';
import { PropsWithChildren } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSidebar } from './model/use-sidebar';
import Image from 'next/image';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { sendGtmEvent } from 'shared/lib/send-gtm';
import { sendYMEvent } from 'shared/lib/yandex-metrica';
import { metricsEvent, OperationNames } from 'shared/lib/metricsEvent';

type SidebarButtonProps = {
  icon: JSX.Element;
  href: string;
  onClick?: () => void;
};

const SidebarButton = ({
  icon,
  children,
  href,
  onClick,
}: PropsWithChildren<SidebarButtonProps>) => {
  const pathname = usePathname();
  return (
    <Link href={href} style={{ color: 'inherit', textDecoration: 'none', width: '100%' }}>
      <Button
        variant={'text'}
        fullWidth
        href={href}
        component={'div'}
        onClick={onClick}
        sx={{
          display: 'block',
          color: 'inherit',
          padding: 0,
        }}
      >
        <Box
          px={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: pathname === href ? '#D0CED2' : 'transparent',
            color: pathname === href ? '#FFF' : 'inherit',
          }}
        >
          {icon}
          <Box
            sx={{
              fontSize: '14px',
              lineHeight: '48px',
              fontWeight: 500,
            }}
          >
            {children}
          </Box>
        </Box>
      </Button>
    </Link>
  );
};

export const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const { agent, currentShop, onLogoutClick, resetStores } = useSidebar();

  return (
    <Box
      sx={{
        width: '280px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        p={2}
        sx={{
          height: '226px',
          background: '#FF5A1E',
          position: 'relative',
          display: 'flex',
          alignItems: 'flex-end',
          '&:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          },
        }}
      >
        {agent && (
          <>
            <Box
              sx={{
                zIndex: 10,
                color: '#FFF',
              }}
            >
              <Image src="/assets/user-avatar.svg" alt={'avatar'} width={64} height={64} />
              <Box
                mt={1}
                sx={{
                  fontSize: '15px',
                  lineHeight: '15px',
                  fontWeight: 500,
                  width: '248px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {`${agent?.firstName} ${agent?.lastName}`}
              </Box>
              <Box
                mt={1}
                sx={{
                  width: '248px',
                  fontSize: '18px',
                  lineHeight: '18px',
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {currentShop?.nameByTrader}
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box
          mt={1}
          sx={{
            flex: 1,
          }}
        >
          <SidebarButton
            href={'/'}
            icon={<HomeSidebarIcon width={32} height={38} />}
            onClick={resetStores}
          >
            {t('sidebar.homeButton')}
          </SidebarButton>
          <SidebarButton
            onClick={() => {
              resetStores();
              sendGtmEvent('main_purchase');
              sendYMEvent('reachGoal', 'main_purchase');
              metricsEvent(OperationNames.MainPurchase);
            }}
            href={'/purchase'}
            icon={<PurchaseSidebarIcon width={32} height={38} />}
          >
            {t('sidebar.purchaseButton')}
          </SidebarButton>
          <SidebarButton
            onClick={() => {
              resetStores();
              sendGtmEvent('main_refund');
              sendYMEvent('reachGoal', 'main_refund');
              metricsEvent(OperationNames.MainRefund);
            }}
            href={'/refund'}
            icon={<RefundSidebarIcon width={32} height={38} />}
          >
            {t('sidebar.refundButton')}
          </SidebarButton>
          <SidebarButton
            href={t('navigation.faq.href')}
            icon={
              <HelpIcon
                width={24}
                height={24}
                color={'#6D6F73'}
                style={{
                  marginRight: '8px',
                }}
              />
            }
          >
            {t('navigation.faq.title')}
          </SidebarButton>
          {['ru'].includes(i18n.language) && (
            <Box
              sx={{
                display: 'flex',
                minWidth: '100%',
              }}
            >
              <SidebarButton
                href={
                  'https://www.figma.com/proto/Gq8kQCiU6lzvseVYf2unxY/WebMerchapp-userflow?node-id=7841-26547&t=zY5NsJI8v2qVFklM-0&scaling=scale-down-width&content-scaling=fixed&page-id=7841%3A8317&starting-point-node-id=7841%3A26547&disable-default-keyboard-nav=1&hide-ui=1'
                }
                icon={
                  <PlayIcon
                    width={24}
                    height={24}
                    color={'#6D6F73'}
                    style={{
                      marginRight: '8px',
                    }}
                  />
                }
              >
                {t('demo')}
              </SidebarButton>
            </Box>
          )}
        </Box>
        <Box
          px={2}
          sx={{
            borderTop: 'solid 1px #D0CED2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={onLogoutClick}
        >
          <Box
            sx={{
              fontSize: '14px',
              lineHeight: '48px',
              fontWeight: 500,
            }}
          >
            {t('sidebar.logoutButton')}
          </Box>
          <LogoutIcon width={32} height={38} />
        </Box>
      </Box>
    </Box>
  );
};
